<template>
  <transition name="fade">
    <div
      class="modal-mask row middle-xs center-xs"
      @mousedown="onStartClickOutside"
      @click.stop="onClickOutside"
    >
      <div
        ref="modalWrapper"
        class="modal-wrapper notificationContainer"
        :style="{
          height: modalHeight,
          'max-height': modalMaxHeight,
          overflow: modalOverflow,
          width,
        }"
      >
        <div role="dialog" class="modal-container">
          <!-- header -->
          <div>
            <div v-if="header" class="modal-header row middle-xs between-xs">
              <div class="header-txt">{{ headerText }}</div>
              <div v-if="!hideCloseButton" class="row end-xs cross-mark">
                <font-awesome-icon :icon="['fas', 'times']" @click="onCloseModal" />
              </div>
            </div>
            <!-- close button (when not using header) -->
          </div>

          <!-- body -->
          <div class="modal-body">
            <slot name="modal-body" />
          </div>

          <!-- footer -->
          <div v-if="!hideFooter" class="modal-footer">
            <slot name="modal-footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
  name: 'Modal',

  props: {
    // when true, show header text and action buttons
    header: {
      required: false,
      type: Boolean,
      default: false,
    },
    width: {
      required: false,
      type: String,
      default: () => '',
    },
    // text to display in header
    headerText: {
      required: false,
      type: String,
      default: null,
    },
    hideFooter: {
      required: false,
      type: Boolean,
      default: false,
    },
    enableClickOutSide: {
      required: false,
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modal: null,
      modalHeight: 'auto',
      modalMaxHeight: '810px',
      modalOverflow: 'auto',
      startClick: false,
    };
  },

  computed: {
    modalCounter: sync('common/ui@modal'),
  },

  mounted() {
    this.modalCounter += 1;
    if (this.enableClickOutSide) {
      window.addEventListener('keydown', this.onClickOutside);
    }
    this.modalResize();
  },

  beforeDestroy() {
    this.modalCounter -= 1;
    if (this.enableClickOutSide) {
      window.removeEventListener('keydown', this.onClickOutside);
    }
  },

  methods: {
    modalResize() {
      setTimeout(() => {
        this.modal = document.querySelector('.modal-wrapper');

        if (window.innerHeight < this.modal.firstChild.offsetHeight) {
          this.modalHeight = `${window.innerHeight - 100}px`;
          this.modalOverflow = 'auto';
        } else {
          this.modalMaxHeight = `${window.innerHeight - 100}px`;
        }

        window.onresize = () => {
          if (window.innerHeight < this.modal.offsetHeight) {
            this.modalHeight = `${window.innerHeight - 100}px`;
            this.modalOverflow = 'scroll';
          }
        };
      }, 50);
    },

    // events

    onCloseModal(event) {
      this.$emit('close', event);
    },

    onSaveAndClose(event) {
      this.$emit('saveAndClose', event);
    },

    onClickOutside(event) {
      if (!this.enableClickOutSide) {
        return;
      }

      if ((this.$el === event.target || event.keyCode === 27) && this.startClick) {
        this.onCloseModal(event);
      }
      this.startClick = false;
    },

    /**
     * Fix for KS-1154 - if a click starts *inside* the modal but ends
     * *outside*, (like when highlighting some text), then it triggers the modal
     * to close. We avoid that by tracking where the click originated. i.e., it
     * must both start and end outside the modal.
     *
     * Note: this bug only occurs in Chrome (not on Firefox).
     */
    onStartClickOutside(event) {
      if (this.$el === event.target) {
        this.startClick = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
}

body.advance-filters .modal-wrapper {
  overflow: scroll !important;
}

.modal-wrapper {
  vertical-align: middle;
}
.modal-container {
  padding: 30px;
  margin: 0px auto;
  color: $grey_4;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  .header-txt {
    font-size: 20px;
    color: $grey_4;
    text-align: left;
  }
  .header-btns {
    text-align: right;
    .header-btn {
      margin-left: 15px;
    }
  }
}
.notificationContainer {
  .modal-container {
    padding: 0 !important;
    margin: 0px auto;
    border-radius: 7px;
    color: #48525c;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease-in-out;

    .header-txt {
      font-size: 20px;
      color: var(--primarycolor);
      width: 90%;
      text-align: left;
    }
    .header-btns {
      text-align: right;
      .header-btn {
        margin-left: 15px;
      }
    }
    .header-close {
      position: absolute;
      right: 28px;
      top: 20px;
      cursor: pointer;
      color: #b8c2cc;
      &:hover {
        color: var(--primarycolor);
      }
    }
    .modal-header {
      font-size: 20px;
      color: #d5d5d5;
      position: relative;
      height: 68px;
      padding: 20px 28px;
      border-bottom: 1px solid #e9eaeb;
      .cross-mark {
        color: #999 !important;
        right: -17px;
        position: absolute;
        bottom: 38px;
        stroke-width: 2px;
        cursor: pointer;
      }
    }
    .modal-footer {
      background-color: #eef3f6;
      height: 80px;
      text-align: left;
      padding: 20px 28px;
      border-radius: 0 0 7px 7px;
    }
  }
}
.modal-header {
  padding: 0;
  font-size: 20px;
  color: #d5d5d5;
}
.cdp-top {
  position: relative;
  height: 68px;
  padding: 20px 28px;
  border-bottom: 1px solid #e9eaeb;
}
</style>

<style lang="scss">
/* Slotted components do weird things to scoped styles. Easiest way to ensure
that text is the correct color within modals is to unscope the CSS here but use
the appropriate modal class scope. */
.modal-mask.selfServMask {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 46, 56, 0.95);
  transition: opacity 0.3s ease-in-out;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
.modal-wrapper .form-input-field {
  color: $grey_4 !important;
}

// Override styles of some elements in modals
.modal-body {
  .form-click {
    padding: 0 11px 0 0 !important;
    margin: 0 0 20px !important;
    border: 1px solid $grey_6 !important;
  }
  .form-input {
    position: relative;
    margin: 0 0 20px;

    &.form-date svg {
      top: 14px;
      right: 20px;
      left: auto;
      margin: 0;
    }

    .form-input-field.v-select {
      border: 1px solid $grey_6;
    }
  }
  .modal-input {
    width: 100%;
  }
  .errors {
    margin: 0 0 20px;
    li {
      padding: 0 0 5px;
      color: $alert;
    }
  }
}
.notificationContainer {
  .btn-outline {
    background-color: transparent;
    border: 1px solid #d6d6d6;
    color: #555 !important;
  }
  .modal-footer {
    .mvc-btn {
      margin-left: 0 !important;
      &:last-child {
        margin-left: 20px !important;
      }
      &:hover {
        color: #fff !important;
      }
    }
  }
  .modal-wrapper-box {
    padding: 20px 28px;
    min-height: 160px !important;
    text-align: left;
    font-size: 14px;
    .container-text {
      a {
        display: inline-block;
        color: #28a8b5;
        text-decoration: none;
        svg {
          color: #b4bfc8;
          font-size: 12px;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
